/** @jsxImportSource @emotion/react */
import { worker } from "index";
import React, { useEffect } from "react";
import { useVibrate } from "react-use";

function App() {
    const [vibrating, setVibrating] = React.useState(false);
    const [exampleValue, setExampleValue] = React.useState<null | number>(null);

    useVibrate(vibrating, [100], false);

    useEffect(() => {
        if (vibrating) {
            setTimeout(() => setVibrating(false), 200);
        }
    }, [vibrating]);

    worker.onmessage = ({ data: { type, payload } }) => {
        requestAnimationFrame(() => {
            if (type === "TEST_MESSAGE") {
                let p: number = payload;
                setExampleValue(p);
            }
        });
    };

    return (
        <div
            css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
            }}
        >
            <img
                src="logo.svg"
                alt="HVAC+ Logo"
                style={{ width: "100%", maxWidth: 200, padding: "0 10px" }}
                onClick={() => {
                    setVibrating(true);
                }}
            />
            <h1 css={{ textAlign: "center", color: "#fff" }}>HVAC+ Controller</h1>
            <p css={{ textAlign: "center", color: "#99aab5", margin: 0 }}>
                This app is in development. {exampleValue && `r: ${exampleValue}`}
            </p>
        </div>
    );
}

export default App;
