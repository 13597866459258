import { Global } from "@emotion/react/macro";
import SWUpdateHandler from "components/serviceWorkers/SWUpdateHandler";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./fonts/fonts.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

export const worker = new Worker(new URL("./workers/ws-worker/worker.ts", import.meta.url));

worker.postMessage({
    type: "STARTUP",
});

worker.onmessage = ({ data: { type, payload } }) => {};

ReactDOM.render(
    <React.StrictMode>
        <Global
            styles={{
                body: {
                    fontFamily: "'Google Sans Display', sans-serif",
                    backgroundColor: "#23272a",
                    height: "100vh",
                    margin: 0,
                },
                "#root": { height: "100%" },
            }}
        />
        <SWUpdateHandler />
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// ! This has been moved to the SWUpdateHandler component
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
