// Service Worker Update Handler
import { FunctionComponent, useEffect, useState } from "react";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";

const SWUpdateHandler: FunctionComponent<{}> = () => {
    // showReload
    const [, setShowReload] = useState(false);
    // waitingWorker
    const [, setWaitingWorker] = useState<ServiceWorker | null>(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);

        // Skip waiting service worker if there is an update
        registration.waiting?.postMessage({ type: "SKIP_WAITING" });

        // Reload the window to install the new worker
        // Should probably include some sort of modal for this to ask the user
        window.location.reload();
    };

    useEffect(() => {
        serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
        console.log("SWUpdateHandler: useEffect");
    }, []);

    return null;
};

export default SWUpdateHandler;
